import * as React from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { connect } from "react-redux"
import { Link } from "gatsby"

import Header from "../components/landing/header"
import Footer from "../components/landing/footer"

const Blog = ({ darkMode }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulBlogPost {
          edges {
            node {
              internalName
              title
              slug
              image {
                gatsbyImageData(
                  quality: 100
                  width: 600
                  height: 450
                  layout: FULL_WIDTH
                )
              }
              body {
                raw
              }
            }
          }
        }
      }
    `
  )

  return (
    <div
      className={` ${
        darkMode
          ? "bg-gradient-to-b from-[#060525] via-[#02096E] via-[#030756]  via-[#130412]  via-[#050534] via-[#06041F] to-[#060317]"
          : "bg-white"
      } overflow-hidden min-h-screen`}
    >
      <Header />
      <div
        className={` ${
          darkMode ? "text-white" : "text-dark"
        } container mx-auto my-10`}
      >
        <h1 className="text-3xl uppercase text-center xl:text-5xl font-bold ">
          Blog
        </h1>
        <p className="text-sm text-center pt-3">
          Exploring the Boundless Possibilities of Artificial Intelligence
        </p>
      </div>
      <div className="container mx-auto">
        <div class="flex flex-wrap justify-center px-5 gap-10">
          {data.allContentfulBlogPost.edges.map(({ node }, idx) => {
            return (
              <div
                onClick={() => navigate(`/blog/${node.slug}`)}
                key={idx}
                class="transition duration-700 ease-in-out cursor-pointer w-full md:w-1/3 lg:w-1/4 text-white text-center border-2 border-sky-500 rounded-xl shadow-lg shadow-blue-500/50"
              >
                <GatsbyImage
                  image={node.image.gatsbyImageData}
                  alt={node.title}
                  className="rounded-t-xl"
                />
                <div className="py-5">
                  <h1
                    className={` ${
                      darkMode ? "text-white" : "text-black"
                    } mb-1 text-xl font-bold uppercase px-4`}
                  >
                    {node.internalName}
                  </h1>
                  <p
                    className={` ${
                      darkMode ? "text-white" : "text-black"
                    } text-sm px-5`}
                  >
                    {node.title}
                  </p>
                  <Link to="/blog/${node.slug}">
                    <button className=" transition duration-700 ease-in-out bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] text-white font-bold py-1 px-5 mt-4 text-sm rounded ">
                      READ MORE
                    </button>
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="p-10" />
      <Footer />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    darkMode: state.utils.darkMode,
  }
}

export default connect(mapStateToProps)(Blog)
