import React from "react"
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"
import { Link } from "gatsby"
import { connect } from "react-redux"

const Footer = ({ darkMode }) => {
  return (
    <div className="container mx-auto text-gray-200 ">
      {/* Line Seperator */}
      <div className="h-0.5 bg-gray-200"></div>
      {/* Footer */}

      <div className="flex flex-col-reverse md:flex-row justify-between items-center py-5 ">
        {/* Left */}
        <div className="flex items-center gap-5">
          <div className="  text-lg  ">
            <ul className="flex gap-3">
              <li className="hover:opacity-100 cursor-pointer duration-150 rounded-full bg-pink-400 text-white p-1">
                <FaFacebookF />
              </li>
              <li className="hover:opacity-100 cursor-pointer duration-150 rounded-full bg-pink-400 text-white p-1">
                <FaTwitter />
              </li>
              <li className="hover:opacity-100 cursor-pointer duration-150 rounded-full bg-pink-400 text-white p-1">
                <FaInstagram />
              </li>
            </ul>
          </div>
        </div>

        {/* Left */}
        <div className="flex items-center gap-5 mb-5 md:mb-0">
          <div className="text-xs">
            <ul
              className={` ${
                darkMode ? "text-white" : "text-black"
              } flex gap-2 `}
            >
              <Link to="/terms">
                <li className="border-r px-5">Terms </li>
              </Link>
              <Link to="/privacy-policy">
                <li className="border-r px-5">Privacy </li>
              </Link>

              <li className="pl-2">
                © {new Date().getFullYear()} Liyana AI, LLC
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    darkMode: state.utils.darkMode,
  }
}

export default connect(mapStateToProps)(Footer)
